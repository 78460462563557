﻿@import "~lightslider/dist/css/lightslider.css";

.lSAction > a {
    @extend .icon;
    display: none;
    font-size: 30px;
    line-height: 1;
    text-decoration: none;
    color: transparentize(#fff, 0.5);
    background: none;
    width: auto;
    height: auto;
    padding: 10px;
    border: 2px solid;
    border-radius: 50px;
    margin-top: -25px;

    @media (min-width: $screen-xl-min) {
        display: block;
    }

    &:before {
        vertical-align: top;
    }
}

.lSAction > .lSPrev {
    @extend .icon-chevron-left;
    left: 25px;
}

.lSAction > .lSNext {
    @extend .icon-chevron-right;
    right: 25px;
}

.lSSlideOuter {
    position: relative;

    .lSPager.lSpg {
        position: absolute;
        bottom: 12px;
        z-index: 10;
        text-align: center;
        width: 100%;

        li {
            display: inline-block;

            a {
                background: rgba(#000, 0.2);
                width: 12px;
                height: 12px;
                border: 2px solid rgba(#fff, 0.2);
            }

            &:hover a,
            &.active a {
                background: rgba(#000, 0.4);
                border-color: rgba(#fff, 0.6);
            }

            &:hover a {
                border-color: #fff;
            }
        }
    }

    
    @media (max-width: $screen-md-min) {
        &.slideshow .lSPager.lSpg {
            position: static;
        }
    }
}