@mixin chevron($icon: $icon-chevron-right) {
    &:after {
        @include icon($icon);
        position: relative;
        top: -2px;
        vertical-align: middle;
        font-size: 14px;
        color: inherit;
        height: 14px;
        width: 14px;
        padding: 0 0 0 4px;
        @content;
    }
}