﻿.panel-collapsible {
    [data-toggle] {
        position: relative;
        cursor: pointer;
        padding-right: 70px;

        &:after {
            @include icon($icon-chevron-up);

            position: absolute;
            top: 0;
            right: 0;
            line-height: 70px;
            text-align: center;
            background: transparentize($brand-primary, 0.9);
            width: 50px;
            height: 100%;
        }

        &.collapsed:after {
            content: $icon-chevron-down;
        }
    }
}

.panel,
.panel-heading {
    border: none;
    border-radius: 0;
    box-shadow: none;
}

h2.panel-title {
    text-transform: none;
    padding: 0 !important;

    &:before {
        display: none;
    }
}

.panel-collapse {
    overflow: hidden;

    .panel-body {
        padding: 0 $panel-body-padding;
        border-left: 1px solid;
        margin: $panel-body-padding 0 $panel-body-padding $panel-body-padding;

        *:last-child {
            margin-bottom: 0;
        }
    }
}