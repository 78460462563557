﻿$icon-arrow-left: "\ea01";
$icon-bonzi-use: "\ea02";
$icon-breeder: "\ea03";
$icon-calendar: "\ea04";
$icon-chevron-down: "\ea05";
$icon-chevron-down-big: "\ea06";
$icon-chevron-down-small: "\ea07";
$icon-chevron-left: "\ea08";
$icon-chevron-left-big: "\ea09";
$icon-chevron-left-small: "\ea0a";
$icon-chevron-right: "\ea0b";
$icon-chevron-right-big: "\ea0c";
$icon-chevron-right-small: "\ea0d";
$icon-chevron-up: "\ea0e";
$icon-chevron-up-big: "\ea0f";
$icon-chevron-up-small: "\ea10";
$icon-crop-time: "\ea11";
$icon-cross: "\ea12";
$icon-facebook: "\ea13";
$icon-flower-count: "\ea14";
$icon-flower-size: "\ea15";
$icon-flower-type: "\ea16";
$icon-flowers-per-bulbsize: "\ea17";
$icon-form: "\ea18";
$icon-home: "\ea19";
$icon-instagram: "\ea1a";
$icon-item-number: "\ea1b";
$icon-leaf-color: "\ea1c";
$icon-linkedin: "\ea1d";
$icon-mail: "\ea1e";
$icon-packaging-type: "\ea1f";
$icon-patent: "\ea20";
$icon-pinterest: "\ea21";
$icon-plant-height: "\ea22";
$icon-plant-type-cut: "\ea23";
$icon-plant-type-pot: "\ea24";
$icon-pointer: "\ea25";
$icon-product-color: "\ea26";
$icon-search: "\ea27";
$icon-share: "\ea28";
$icon-spike-length: "\ea29";
$icon-star: "\ea2a";
$icon-top-width: "\ea2b";
$icon-twitter: "\ea2c";
$icon-youtube: "\ea2d";

@at-root {
    @font-face {
        font-family: icon;
        
        src: url("../../fonts/icon.woff2") format("woff2"), url("../../fonts/icon.woff") format("woff"); 
        font-style: normal;
        font-weight: normal;
    }
}

@mixin icon($content: none) {
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    font-family: icon;
    line-height: 1;
    text-transform: none;
    speak: none;
    content: $content;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon:before {
    @include icon;
}

.icon-arrow-left:before { content: $icon-arrow-left; }
.icon-bonzi-use:before { content: $icon-bonzi-use; }
.icon-breeder:before { content: $icon-breeder; }
.icon-calendar:before { content: $icon-calendar; }
.icon-chevron-down:before { content: $icon-chevron-down; }
.icon-chevron-down-big:before { content: $icon-chevron-down-big; }
.icon-chevron-down-small:before { content: $icon-chevron-down-small; }
.icon-chevron-left:before { content: $icon-chevron-left; }
.icon-chevron-left-big:before { content: $icon-chevron-left-big; }
.icon-chevron-left-small:before { content: $icon-chevron-left-small; }
.icon-chevron-right:before { content: $icon-chevron-right; }
.icon-chevron-right-big:before { content: $icon-chevron-right-big; }
.icon-chevron-right-small:before { content: $icon-chevron-right-small; }
.icon-chevron-up:before { content: $icon-chevron-up; }
.icon-chevron-up-big:before { content: $icon-chevron-up-big; }
.icon-chevron-up-small:before { content: $icon-chevron-up-small; }
.icon-crop-time:before { content: $icon-crop-time; }
.icon-cross:before { content: $icon-cross; }
.icon-facebook:before { content: $icon-facebook; }
.icon-flower-count:before { content: $icon-flower-count; }
.icon-flower-size:before { content: $icon-flower-size; }
.icon-flower-type:before { content: $icon-flower-type; }
.icon-flowers-per-bulbsize:before { content: $icon-flowers-per-bulbsize; }
.icon-form:before { content: $icon-form; }
.icon-home:before { content: $icon-home; }
.icon-instagram:before { content: $icon-instagram; }
.icon-item-number:before { content: $icon-item-number; }
.icon-leaf-color:before { content: $icon-leaf-color; }
.icon-linkedin:before { content: $icon-linkedin; }
.icon-mail:before { content: $icon-mail; }
.icon-packaging-type:before { content: $icon-packaging-type; }
.icon-patent:before { content: $icon-patent; }
.icon-pinterest:before { content: $icon-pinterest; }
.icon-plant-height:before { content: $icon-plant-height; }
.icon-plant-type-cut:before { content: $icon-plant-type-cut; }
.icon-plant-type-pot:before { content: $icon-plant-type-pot; }
.icon-pointer:before { content: $icon-pointer; }
.icon-product-color:before { content: $icon-product-color; }
.icon-search:before { content: $icon-search; }
.icon-share:before { content: $icon-share; }
.icon-spike-length:before { content: $icon-spike-length; }
.icon-star:before { content: $icon-star; }
.icon-top-width:before { content: $icon-top-width; }
.icon-twitter:before { content: $icon-twitter; }
.icon-youtube:before { content: $icon-youtube; }
