﻿@import "variables";

table {
    @extend .table, .table-condensed, .table-striped;
    width: 100%;
    border-bottom: 1px solid $table-border-color;

    > thead > tr > th {
        font-weight: 600;
        font-size: $font-size-base;
        text-transform: uppercase;
        background: $brand-secondary;
        padding: $table-cell-padding;
        border-bottom: none;
    }

    > tbody {
        font-size: $font-size-small;

        > tr:first-child {
            > td,
            > th {
                border-top: none;
            }
        }
    }
}