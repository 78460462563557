@import "mixins";
@import "variables";

// Inverted button
.btn-inverted {
    @include button-variant($brand-primary, #fff, #fff);
}

// Outline buttons
.btn-outline-default {
  @include button-outline-variant($btn-default-color, $btn-default-bg);
}

.btn-outline-primary {
  @include button-outline-variant($btn-primary-color, $btn-primary-bg);
}

.btn-outline-secondary {
  @include button-outline-variant($btn-secondary-color, $btn-secondary-bg);
}

// Arrows
.arrow {
    @include chevron;
    font: 600 16px/24px $font-family-sans-serif;
    text-transform: uppercase;
    letter-spacing: $headings-letter-spacing;

    @include hover-focus-active {
        text-decoration: none;
    }
}

// Buttons
.button,
.btn {
    @extend .arrow;
    padding: 12px 20px;
    transition: all 0.2s ease-in-out;

    &.btn-primary {
        @include hover-focus-active {
            background: mix(#fff, $brand-primary, 25%);
            border-color: mix(#fff, $brand-primary, 25%);
        }
    }

    &.btn-secondary {
        color: $brand-primary;
        background: $brand-secondary;

        @include hover-focus-active {
            background: mix(#fff, $brand-secondary, 25%);
        }
    }
}

.btn-sm {
    padding: 6px 10px;
}