﻿.row,
.container,
.container-fluid {
    &.no-gutter {
        margin-right: 0;
        margin-left: 0;

        & > [class*="col-"] {
            padding-right: 0;
            padding-left: 0;
        }
    }
}