// Colors
$gray: #edf0f3; // Light grayish blue (alternating sections)
$gray-light: #e5e9ee; // Light grayish blue (body background)
$brand-primary: #002458; // Dark blue (brand color)
$brand-primary-dark: #00204f; // Very dark blue (footer)
$brand-primary-light: #99a7bc; // Grayish blue
$brand-secondary: #cbd401; // Strong yellow (alternate brand color)
$brand-secondary-dark: #b6c20a; // Dark strong yellow (alternate dark brand color)

// Scaffolding
$body-bg: $gray-light;
$text-color: $brand-primary;
$text-bg: #fff;
$link-color: inherit;
$link-hover-color: inherit;

// Typography
$font-path: "../../fonts/";
$font-family-sans-serif: "Titillium Text", Verdana, Geneva, sans-serif;

$font-size-base: 16px;
$font-size-normal: ceil(($font-size-base * 1.25)); // 20px
$font-size-small: ceil(($font-size-base * 0.8125)); // ~13px

$font-size-h1: ceil(($font-size-base * 2.5)); // 40px
$font-size-h2: ceil(($font-size-base * 2.5)); // 40px
$font-size-h3: ceil(($font-size-base * 1.875)); // 30px
$font-size-h4: $font-size-normal; // 20px
$font-size-h5: $font-size-base;
$font-size-h6: $font-size-small; // 13px

$headings-font-weight: 900;
$headings-text-transform: uppercase;
$headings-letter-spacing: 0.03em;

// Iconography
$icon-font-path: "~bootstrap-sass/assets/fonts/bootstrap/";

// Buttons
$btn-font-weight: inherit;
$btn-default-color: $brand-primary;
$btn-default-bg: $brand-secondary;
$btn-default-border: $btn-default-bg;

$btn-primary-color: #fff;
$btn-primary-bg: $brand-primary;
$btn-primary-border: $btn-primary-bg;

$btn-secondary-color: #fff;
$btn-secondary-bg: $brand-secondary;
$btn-secondary-border: $btn-secondary-bg;

// Forms
$input-bg: transparentize($brand-primary, 0.93);
$input-color: $brand-primary;

// Media queries breakpoints
$screen-sm: 640px; // tablet
$screen-md: 980px; // desktop
$screen-lg: 1280px; // large-dekstop
$screen-xl: 1440px; // extra-large-desktop
$screen-ml: 1920px; // mega-large-desktop

$screen-sm-min: $screen-sm - 1px;
$screen-md-min: $screen-md - 1px;
$screen-lg-min: $screen-lg - 1px;
$screen-xl-min: $screen-xl - 1px;
$screen-ml-min: $screen-ml - 1px;

// Grid system
$grid-gutter-width: 36px;
$navbar-margin-bottom: 0;
$grid-float-breakpoint: $screen-md;

// Tables
$table-cell-padding: 12px 18px;
$table-condensed-cell-padding: 8px 18px;
$table-bg-accent: transparentize($brand-primary, 0.93);
$table-bg-hover: $brand-primary-light;
$table-border-color: $brand-primary-light;

// Container sizes
$container-tablet: (600px + $grid-gutter-width);
$container-large-desktop: (1240px + $grid-gutter-width);

// Navbar
$navbar-default-color: $text-bg;
$navbar-default-bg: $text-color;
$navbar-default-border: $navbar-default-bg;

// Navbar links
$navbar-default-link-color: $text-bg;
$navbar-default-link-hover-color: $text-color;
$navbar-default-link-hover-bg: $brand-secondary;
$navbar-default-link-active-color: $brand-secondary;
$navbar-default-link-active-bg: transparent;

// Navbar toggle
$navbar-default-toggle-hover-bg: transparent;
$navbar-default-toggle-icon-bar-bg: $text-bg;
$navbar-default-toggle-border-color: transparent;

// Pagination
$pagination-bg: $brand-secondary;
$pagination-border: $pagination-bg;
$pagination-hover-bg: mix(#fff, $brand-secondary, 25%);
$pagination-hover-border: $pagination-hover-bg;

// Panels
$panel-bg: transparentize($brand-primary-light, 0.93);
$panel-body-padding: 24px;
$panel-heading-padding: 24px;
$panel-default-text: $brand-primary;
$panel-default-heading-bg: $gray-light;

// List groups
$list-group-border-radius: 0;
$list-group-bg: transparent;
$list-group-border: $brand-primary-light;
$list-group-accent-bg: transparentize($brand-primary, 0.93); //$gray;
$list-group-hover-bg: $gray-light;