﻿.articles {
    .list-group-item {
        border-width: 1px 0;

        &:nth-child(even) {
            background: $list-group-accent-bg;

            @include hover-focus {
                background: $list-group-hover-bg;
            }
        }

        time {
            display: inline-block;
            font-size: $font-size-small;
            color: $brand-primary-light;
            width: 10%;
            min-width: 6em;
        }

        span {
            font-size: $font-size-base;
            font-weight: 600;
            text-transform: uppercase;
            color: $brand-primary;
        }
    }
}
