@mixin placeholder($color, $font) {
	&::-webkit-input-placeholder {
		// Webkit
		color: $color;
		font: $font;
	}

	&:-moz-placeholder {
		// FF 4-18
		opacity: 1;
		color: $color;
		font: $font;
	}

	&::-moz-placeholder {
		// FF 19+
		opacity: 1;
		color: $color;
		font: $font;
	}

	&:-ms-input-placeholder {
		// IE 10+
		color: $color;
		font: $font;
	}

	&::input-placeholder {
		color: $color;
		font: $font;
	}
}
