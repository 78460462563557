﻿.twitter-typeahead {
	width: 100%;

	.tt-hint {
		color: $brand-primary-light;
	}

	.tt-menu {
		overflow: hidden;
		width: 100%;
		background: #fff;
		padding-top: $border-radius-base;
		margin-top: -$border-radius-base;
		border-bottom-left-radius: $border-radius-base;
		border-bottom-right-radius: $border-radius-base;
	}

	.product-name & .tt-menu {
		border: 1px solid $gray-light;
	}

	.tt-suggestion {
		padding: 9px 16px;
	}

	.tt-suggestion.tt-cursor,
	.tt-suggestion:hover {
		cursor: pointer;
		background-color: $gray-light;
	}
}