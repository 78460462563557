﻿@mixin container-responsive($width, $height) {
    position: relative;
    display: block;
    overflow: hidden;
    height: 0;
    padding: 0 0 (100% * $height / $width);
}

@mixin container-responsive-item($width: 100%, $height: 100%) {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: $width;
    height: $height;
}