﻿// Headings
h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
    text-transform: $headings-text-transform;
    letter-spacing: $headings-letter-spacing;
}

/*
    Titles: Titillium Text 999 (900/'black')
    Text: Titillium Text 250 (normal), Titillium Text 800 (bold)
    Buttons: Titillium Text 600 (600/'medium')
    //Slideshow paging arrows: Nexa Light (200/'light')
    //Link arrows: Nexa Book (normal)
    //Product paging arrows: Nexa Bold (bold)
*/

@at-root {
    // Titillium
    @font-face {
        font-family: "Titillium Text";
        src: url("#{$font-path}titilliumtext250wt.eot");
        src: url("#{$font-path}titilliumtext250wt.eot?#iefix") format("embedded-opentype"),
             url("#{$font-path}titilliumtext250wt.woff2") format("woff2"),
             url("#{$font-path}titilliumtext250wt.woff") format("woff"),
             url("#{$font-path}titilliumtext250wt.ttf") format("truetype");
        font-weight: 400; // normal
        font-style: normal;
    }

    @font-face {
        font-family: "Titillium Text";
        src: url("#{$font-path}titilliumtext250wt.eot");
        src: url("#{$font-path}titilliumtext250wt.eot?#iefix") format("embedded-opentype"),
             url("#{$font-path}titilliumtext250wt.woff2") format("woff2"),
             url("#{$font-path}titilliumtext250wt.woff") format("woff"),
             url("#{$font-path}titilliumtext250wt.ttf") format("truetype");
        font-weight: 200; // 'light'
        font-style: normal;
    }

    @font-face {
        font-family: "Titillium Text";
        src: url("#{$font-path}titilliumtext600wt.eot");
        src: url("#{$font-path}titilliumtext600wt.eot?#iefix") format("embedded-opentype"),
             url("#{$font-path}titilliumtext600wt.woff2") format("woff2"),
             url("#{$font-path}titilliumtext600wt.woff") format("woff"),
             url("#{$font-path}titilliumtext600wt.ttf") format("truetype");
        font-weight: 600; // 'medium'
        font-style: normal;
    }

    @font-face {
        font-family: "Titillium Text";
        src: url("#{$font-path}titilliumtext800wt.eot");
        src: url("#{$font-path}titilliumtext800wt.eot?#iefix") format("embedded-opentype"),
             url("#{$font-path}titilliumtext800wt.woff2") format("woff2"),
             url("#{$font-path}titilliumtext800wt.woff") format("woff"),
             url("#{$font-path}titilliumtext800wt.ttf") format("truetype");
        font-weight: 700; // bold
        font-style: normal;
    }

    @font-face {
        font-family: "Titillium Text";
        src: url("#{$font-path}titilliumtext999wt.eot");
        src: url("#{$font-path}titilliumtext999wt.eot?#iefix") format("embedded-opentype"),
             url("#{$font-path}titilliumtext999wt.woff2") format("woff2"),
             url("#{$font-path}titilliumtext999wt.woff") format("woff"),
             url("#{$font-path}titilliumtext999wt.ttf") format("truetype");
        font-weight: 900; // 'black'
        font-style: normal;
    }
}