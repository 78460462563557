﻿// Form controls and buttons
label {
	margin-bottom: 0;
}

input[type=text],
input[type=search],
input[type=password],
input[type=email],
input[type=tel],
textarea,
select {
	font: 600 16px/30px $font-family-sans-serif;
	color: $brand-primary;
	background: transparentize($brand-primary, 0.93);
	border: 0;
	padding: $padding-base-vertical $padding-base-horizontal;
	outline: none;
	width: 100%;
	height: 50px;
	margin: 0;
	border-radius: 0;

	&[data-val-required] {
		border-left: 2px solid mix($brand-primary, #fff, 40%);
	}

	&.input-validation-error {
		background: mix($brand-secondary, #fff, 20%);
		border-color: mix($brand-secondary, #fff, 40%);
	}
}

input[type=text],
input[type=search],
input[type=password],
input[type=email],
input[type=tel],
textarea {
	appearance: none;
}

textarea {
	display: block;
	overflow: auto;
	height: auto;
	resize: vertical;
	min-height: 180px;
}

select {
	font-size: 12px;
	padding: 7px 6px;
	vertical-align: bottom;

	option {
		padding: 10px 15px;
	}
}

input[type=submit],
button {
	vertical-align: top;
	cursor: pointer;
	border: none;
	outline: none;
	text-align: left;

	// Remove extra padding
	&::-moz-focus-inner {
		border: 0;
		padding: 0;
	}
}

// Validation summary
.validation-summary-errors {
	color: #333;
	background: $brand-secondary;
	padding: 9px 9px 1px;
	border: 1px solid $brand-secondary;
	border-radius: 3px;
	margin: 0 0 10px;

	p,
	ul {
		list-style: none;
		padding: 0;
		margin: 0 0 8px;
	}

	ul li {
		padding: 0 !important;

		&:before,
		&:after {
			content: none !important;
		}
	}
}

// Honeypot
fieldset#e1afdf62-b46e-40ee-8793-fe69120e747a,
.umbraco-forms-field.email2 {
	display: none;
}

// Form
.umbraco-forms-page {
	width: 100%;
	margin: 0 auto;
	padding: 0;

	fieldset + fieldset {
		margin-top: 10px;
	}

	p {
		overflow: hidden;
		width: 100%;
		margin: 0 0 10px;

		label {
			float: left;
			font: 20px/30px $font-family-sans-serif;
			width: 100%;
			padding: 7px 0 13px 0;

			@media (min-width: $screen-sm) {
				width: 195px;
				padding-right: 20px;
			}
		}

		input[type=text],
		input[type=search],
		input[type=password],
		input[type=tel],
		input[type=email],
		textarea,
		select {
			float: left;
			vertical-align: top;
			width: 100%;

			@media (min-width: $screen-sm) {
				width: calc(100% - 195px);
			}
		}
	}

	.button-container {
		text-align: right;
	}
}