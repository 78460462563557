﻿@import "../_variables.scss";

@mixin border-header($top, $height) {
	position: relative;
	padding: 0 0 0 30px;

	&:before {
		content: '';
		position: absolute;
		top: $top;
		left: 0;
		width: 2px;
		height: $height;
		background: $brand-primary;
	}
}