﻿.pagination {
    > li {
        > a,
        > span {
            font: 600 16px/24px $font-family-sans-serif;
            text-transform: uppercase;
            text-decoration: none !important;
            letter-spacing: $headings-letter-spacing;
            padding: 13px 20px;
        }
    }
}