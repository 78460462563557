@mixin button-outline-variant($color, $color-hover: #fff) {
    color: $color;
    background-image: none;
    background-color: transparent;
    border-color: $color;

    @include hover-focus-active {
        color: $color-hover;
        background-color: $color;
        border-color: $color;
    }

    &:focus,
    &.focus {
        box-shadow: 0 0 0 2px rgba($color, .5);
    }
}
