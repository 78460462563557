﻿@import "~selectize/dist/css/selectize.css";
@import "../variables";
@import "../functions/solidify";

.selectize-control {
    $button-size: 50px;

    .selectize-dropdown,
    .selectize-input,
    .selectize-input input {
        display: block;
        color: $input-color;
        background: solidify($input-bg, #fff);
        height: auto;
        padding: 0;
        border: none;
        border-radius: 0;
        box-shadow: none;
        margin: 0;
    }

    .selectize-input,
    &.multi .selectize-input {
        min-height: $button-size;
        padding-top: $padding-base-vertical;
        padding-right: $padding-base-horizontal + $button-size;
        padding-bottom: $padding-base-vertical;
        padding-left: $padding-base-horizontal;
    }

    .selectize-input {
        &:after {
            @include icon($icon-chevron-down);
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            z-index: 1;
            font-size: $font-size-normal;
            line-height: $button-size;
            color: $brand-primary;
            background: transparentize($brand-primary, 0.9);
            width: $button-size;
            text-align: center;
        }

        &.dropdown-active::before {
            content: none;
        }
    }

    &.multi {
        .selectize-input {
            input {
                height: $button-size - ($padding-base-vertical * 2) - 10px;
                margin: 4px 0 !important;
            }

            .item,
            .item.active {
                background: $brand-secondary;
                padding: 5px 10px;
                margin: 5px;
            }
        }

        &.has-items .selectize-input input {
            margin: 0 !important;
        }
    }

    &.plugin-remove_button [data-value] .remove {
        padding: 5px 2px 0 0;
        border: none;
        border-radius: 0;
    }
}